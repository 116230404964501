import React from "react";
import logo from "../Assets/Logo/logo.webp";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  const renderHeaderContent = () => {
    const { pathname } = location;
    if (pathname === "/") {
      return (
        <Link class="navbar-brand" to="/">
          <img src={logo} alt="Social Academy" className="logo" />
        </Link>
      );
    } else {
      return (
        <Link class="navbar-brand" to="/VH">
          <img src={logo} alt="Social Academy" className="logo" />
        </Link>
      );
    }
  };

  return (
    <>
      <nav class="navbar">
        <div class="container">{renderHeaderContent()}</div>
      </nav>
    </>
  );
};

export default Header;
