import React, { useState } from "react";
import star from "../Assets/star.webp";
import trust from "../Assets/trust.webp";
import Header from "./Header";
import bannerimage from "../Assets/Bannerimage.webp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomModal from "../CustomModal";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Optin = () => {
  const navigate = useNavigate();

  const [addModel, setAddModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [create, SetCreate] = useState({});
  const [validation, setValidation] = useState({});

  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!create.Name) {
      validdateData.Name = "Name is Required";
      Status = false;
    }
    if (!create.Email) {
      validdateData.Email = "Email is Required";
      Status = false;
    }
    if (!create.Number) {
      validdateData.Number = "Number is Required";
      Status = false;
    } else if (create.Number.length !== 12) {
      validdateData.Number = "Invalid Number";
      Status = false;
    }
    if (!create.Location) {
      validdateData.Location = "Location is Required";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const setData = (e, key) => {
    SetCreate({ ...create, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const Payload = [
      {
        Attribute: "FirstName",
        Value: create.Name,
      },
      {
        Attribute: "EmailAddress",
        Value: create.Email,
      },
      {
        Attribute: "Phone",
        Value: create.Number,
      },
      {
        Attribute: "mx_City",
        Value: create.Location,
      },
      {
        Attribute: "Source",
        Value: "Organic",
      },
    ];

    if (CheckValidation()) {
      const response = await axios.post(
        "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r6bb21b3cf1518343d1981ce6646b844f&secretKey=d58ccce3a29496917e48f84d2f40d31061c6b781",
        Payload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.Status === "Success") {
        setLoading(false);
        setAddModel(false);
        setTimeout(() => {
          if (response.data.Status === "Success") {
            navigate("./Masterclass");
          } else {
            navigate("/");
          }
        }, 500);
      } else {
        setLoading(false);
        setButtonDisabled(true);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="mt-md-5">
          <h1 className="title">
            Learn How To Earn At Least&nbsp;
            <span className="parahighlight">50K – 100K </span>INR per month
            <br />
            as a <span className="parahighlight">SIDE HUSTLE</span> or as
            a&nbsp;
            <span className="parahighlight">FREELANCER</span>.
            <h1 className="subsubtitle pt-3">
              (With Zero Expertise & Zero Experience, In Just 30 Days!)
            </h1>
          </h1>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={bannerimage}
                alt="Social Eagle"
                className="bannerimage"
                onClick={() => setAddModel(true)}
              />
            </div>
            <div className="col-lg-6">
              <p className="para">
                A short and to the point MASTERCLASS on how to become a&nbsp;
                <span className="parahighlight">DIGITAL ENTREPRENEUR...</span>
              </p>
              <p className="subpara">
                <span className="icon">✔</span> To live the life that you aspire
                <br />
                <span className="icon">✔</span> With Zero Expertise &amp; Zero
                Experience
                <br />
                <span className="icon">✔</span> To escape from your Unsatisfying
                job
                <br />
                <span className="icon">✔</span> So that you can live with the
                Freedom of Time, Money &amp; Travel.
              </p>
              <div className="alignment">
                <button className="button" onClick={() => setAddModel(true)}>
                  Watch Now For FREE
                </button>
                <div className="row">
                  <div className="col-lg-7 mx-auto ms-auto">
                    <div className="row mt-3">
                      <div className="col-lg-2 col-2"></div>
                      <div className="col-lg-4 col-4 p-0">
                        <div className="alignment">
                          <img src={star} alt="Star" className="starimage" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-4 p-0">
                        <img src={trust} alt="Star" className="trustimage" />
                      </div>
                      <div className="col-lg-2 col-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  addModel */}
      <CustomModal
        open={addModel}
        onClickOutside={() => {
          setAddModel(false);
        }}
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-content  m-auto">
            <div className=" text-center mt-5">
              <h6 class="titlemodel">Watch Now For FREE!</h6>
              <p className="paramodel">
                Enter your info, start watching the&nbsp;
                <span className="parahighlighttext">MASTERCLASS</span>&nbsp;
                immediately
              </p>
            </div>
            <div className="modal-body p-0 ">
              <div className="container">
                <div className="mt-4">
                  <input
                    type="text"
                    className="inputbox"
                    placeholder="Enter Your Full Name*"
                    defaultValue={create.Name}
                    onChange={(e) => {
                      setData(e.target.value, "Name");
                    }}
                  />
                  {validation.Name && (
                    <p className="validationmsg">{validation.Name}</p>
                  )}
                </div>
                <div className="mt-4">
                  <PhoneInput
                    country={"in"}
                    placeholder="Enter Your Mobile Number"
                    autoComplete="off"
                    onChange={(e) => {
                      setData(e, "Number");
                    }}
                    defaultValue={create.Number}
                  />
                  {validation.Number && (
                    <p className="validationmsg">{validation.Number}</p>
                  )}
                </div>
                <div className="mt-4">
                  <input
                    type="text"
                    className="inputbox"
                    placeholder="Enter Your Email Address*"
                    defaultValue={create.Email}
                    onChange={(e) => {
                      setData(e.target.value, "Email");
                    }}
                  />
                  {validation.Email && (
                    <p className="validationmsg">{validation.Email}</p>
                  )}
                </div>
                <div className="mt-4">
                  <input
                    type="text"
                    className="inputbox"
                    placeholder="Enter Your City*"
                    defaultValue={create.Location}
                    onChange={(e) => {
                      setData(e.target.value, "Location");
                    }}
                  />
                  {validation.Location && (
                    <p className="validationmsg">{validation.Location}</p>
                  )}
                </div>
                <button
                  type="submit"
                  className="modelbutton"
                  disabled={buttonDisabled || loading}
                >
                  Watch Now For FREE!
                  {loading ? <i class="fa fa-circle-o-notch fa-spin"></i> : ""}
                </button>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  type="button"
                  className="btn mx-2 submit-btn"
                  onClick={() => {
                    setAddModel(false);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
      <Footer />
    </>
  );
};

export default Optin;
