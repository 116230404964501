import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container">
        <section>
          <p className="footertext">© Social Eagle 2023</p>
          <p className="footertext">
            Client Agreement | Privacy Policy | Terms
            <br />
            This site is not a part of the Facebook website or Facebook Inc.
            Additionally, This site is NOT endorsed by Facebook in any way.
            FACEBOOK is a trademark of FACEBOOK, Inc.
          </p>
          <p className="footertext">
            As stipulated by law, we can not and do not make any guarantees
            about your ability to get results or earn any money with our ideas,
            information, tools or strategies. We just want to help you by giving
            great content, direction and strategies that worked well for us and
            our students and that we believe can move you forward. All of our
            terms, privacy policies and disclaimers for this program and website
            can be accessed via the link above. We feel transparency is
            important and we hold ourselves (and you) to a high standard of
            integrity. Thanks for stopping by. We hope this training and content
            brings you a lot of value.
          </p>
        </section>
      </div>
    </>
  );
};

export default Footer;
