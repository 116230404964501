import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "../src/Css/Style.css";
import "../src/Css/Responsive.css";
import Optin from "./Components/Optin";
import VH from "./Components/VH";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Optin />} />
        <Route exact path="/VH" element={<VH />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
